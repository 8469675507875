import moment from 'moment'
import store from '../redux/store.js'
import informacionHotelAction from '../redux/actions/setInformacionHotel'
import sesion from './session.json'
import informacionHotel from '../../__mocks__/informacionHotel.json'
import {API,graphqlOperation} from 'aws-amplify';
import {getSession} from "../graphql/queries"

import setRoomType from '../../src/redux/actions/setRoomType'

export async function GetToken(username, password){
    const tokenResponse=await (await fetch(`${process.env.BOOKING_ENGINE}/v1/api/auth?username=${username}&password=${password}`,{method: 'POST'})).json()
    const token=tokenResponse.token;
    return token
  }

export const BuscarCuentas=async ()=>{
    const response=await (await fetch(`${process.env.BOOKING_ENGINE}/v1/api/roomtypes?token=${sesion.global.token}&account_id=${sesion.global.accountId}`,
                                        {method: 'GET'})).json()
    const listaRooms=Object.values(response.roomtypes);
    let listaPadres=listaRooms.filter(x=>x.parent_id==0);
    listaPadres.forEach(x=>{
        x.currency=1;
        x.priceList=listaRooms.filter(y=>y.parent_id==x.id).sort((a,b)=>a.adults-b.adults);
        x.token=sesion.global.token
        delete x.parent_id;
        x.roomList=[]})

    return listaPadres
}


export const actualizarInformacionHotel=async (setLoading,dispatch)=>{
    const listaDeptos=store.getState().dormis.listaDeptos;
    const response=await BuscarCuentas().catch(x=>console.log(x));
    console.log(response)
    informacionHotel.roomTypes=[...response,{"configuracion":true}];
    
    //Asignamos la lista de habitaciones correspondiente a cada roomType
    informacionHotel.roomTypes.forEach(x=>{
        x.roomList=listaDeptos.filter(y=>x.name==y.type)
    })
    dispatch(informacionHotelAction(informacionHotel));
    setLoading(false)
    return informacionHotel
}


export const ModificarRoomType=async (roomType,setLoading,dispatch)=>{
        await (await fetch(`${process.env.BOOKING_ENGINE}/v1/api/roomtypes?token=${sesion.global.token}&account_id=${sesion.global.accountId}`,
                    {method: 'PUT',body:JSON.stringify(roomType), headers: { 'Content-Type': 'application/json' }})).json()
         await actualizarInformacionHotel(setLoading,dispatch);  
         dispatch(setRoomType(0)) 
         dispatch(setRoomType(roomType.parent_id)) 
    }
export const CrearRoomType=async (roomType,setLoading,dispatch)=>{
        roomType.token=sesion.global.token;
        roomType.account_id=sesion.global.accountId;
        await (await fetch(`${process.env.BOOKING_ENGINE}/v1/api/roomtypes?token=${sesion.global.token}`,
                    {method: 'POST',body:JSON.stringify(roomType), headers: { 'Content-Type': 'application/json' }})).json()
         await actualizarInformacionHotel(setLoading,dispatch); 
         dispatch(setRoomType(0)) 
         dispatch(setRoomType(roomType.parent_id)) 
    }
export const EliminarRoomType=async (roomType,setLoading,dispatch)=>{
        roomType.token=sesion.global.token;
        const response=await (await fetch(`${process.env.BOOKING_ENGINE}/v1/api/roomtypes/${roomType.id}`,
                    {method: 'DELETE',body:JSON.stringify(roomType), headers: { 'Content-Type': 'application/json' }})).json()
            if (response.errors&&response.errors[0].type=="SubRoomsExists"){
                alert("Error: Para eliminar un tipo de habitacion primero tenes que eliminar los precios y las habitaciones asociadas a la misma")
            }
         await actualizarInformacionHotel(setLoading,dispatch);  
         dispatch(setRoomType(0)) 
         dispatch(setRoomType(roomType.parent_id)) 
    }

export const AgarrarPrecios=async (fechaInicio,fechaFin)=>{
        if (sesion.global&&sesion.global.token&&fechaInicio){
            const response=await (await fetch(`${process.env.BOOKING_ENGINE}/v1/api/prices?token=${sesion.global.token}&plan_id=${sesion.global.planId}&dfrom=${fechaInicio}&dto=${fechaFin}&token=${sesion.global.token}&account_id=${sesion.global.accountId}`,
            {method: 'GET'})).json();
            const divisa=store.getState().dormis.divisa;
            for (var roomId in response.prices){
                 // Ver si la key esta definida en el objeto mismo y no el en padre
                if (response.prices.hasOwnProperty(roomId)) {           
                    for (var fecha in response.prices[roomId]){
                        // Ver si la key esta definida en el objeto mismo y no el en padre
                       if (response.prices[roomId].hasOwnProperty(fecha)) {           
                        response.prices[roomId][fecha].price=Math.round(response.prices[roomId][fecha].price*divisa);
                       }
                   }
                }
            }
            return response;
        }
        else{
            return []
        }
    }

export const ActualizarPrecios=async (fechaInicio,fechaFin,precio,roomId,setLoading,dispatch)=>{
        //Agarramos el valor de la divisa
        const divisa=store.getState().dormis.divisa;
        const objPrice={};
        precio=parseInt(precio)/divisa
        fechaInicio=moment(fechaInicio)
        fechaFin=moment(fechaFin)
        const dias=fechaFin.diff(fechaInicio,"days")
        let tiempoEspera=1000;
        for (let index = 0; index < dias; index++) {
            if(index==0){
                if (precio==0){
                    objPrice[roomId]=[]
                }
                else{
                    objPrice[roomId]={}
                }
             
            }
            //Si el precio es 0 se entiende que hay que eliminarlo
            if (precio==0){
                objPrice[roomId]=[...objPrice[roomId],fechaInicio.format("YYYY-MM-DD")]
            }
            else{
                objPrice[roomId][fechaInicio.format("YYYY-MM-DD")]=precio
            }
            
            fechaInicio.add(1,"days")
            tiempoEspera+=300;
        }
    
        const body={
            "token": sesion.global.token,
            "account_id":sesion.global.accountId,
            "plan_id": sesion.global.planId,
            "price": objPrice
          }
     
        const response=await (await fetch(`${process.env.BOOKING_ENGINE}/v1/api/prices`,
        {method: precio==0?'DELETE':'POST', body:JSON.stringify(body), headers: { 'Content-Type': 'application/json' }})).json();
   
        setTimeout((x)=>{actualizarInformacionHotel(setLoading,dispatch);},tiempoEspera)
    }
    

export const AgarrarDisponibilidad=async (fechaInicio,fechaFin,roomId,setLoading,dispatch)=>{
        const dfrom=moment(fechaInicio).unix()
        const dto=moment(fechaFin).unix()
        const response=await (await fetch(`${process.env.BOOKING_ENGINE}/v1/api/availability?roomtypes[]=${roomId}&dfrom=${dfrom}&dto=${dto}&token=${sesion.global.token}&account_id=${sesion.global.accountId}`,
        {method: 'GET'})).json();
        return response.availability;
    }

export const ActualizarDisponibilidad=async (fechaInicio,fechaFin,disponibilidad,roomId,setLoading,dispatch)=>{
        const objPrice={};
        fechaInicio=moment(fechaInicio)
        fechaFin=moment(fechaFin)
        const dias=fechaFin.diff(fechaInicio,"days")
        let tiempoEspera=1000;
        for (let index = 0; index < dias; index++) {
            if(index==0){
                objPrice[roomId]={}
            }
            
            objPrice[roomId][fechaInicio.format("YYYY-MM-DD")]=disponibilidad
            fechaInicio.add(1,"days")
            tiempoEspera+=300;
        }
    
        const body={
            "token": sesion.global.token,
            "account_id":sesion.global.accountId,
            "availability": objPrice
          }

        const response=await (await fetch(`${process.env.BOOKING_ENGINE}/v1/api/availability`,
        {method: 'POST', body:JSON.stringify(body), headers: { 'Content-Type': 'application/json' }})).json();
        setTimeout((x)=>{actualizarInformacionHotel(setLoading,dispatch);},tiempoEspera)
    }

export const SetSession=async (dispatch)=>{
        let response;
        try {
            response=await API.graphql(graphqlOperation(getSession))
        } catch (error) {
            response={planId: null, token:null, accountId: null}
            console.log("mal",response)
        }
        
        response.created=moment().format()
        sesion.global=JSON.parse(response.data.getSession);
        dispatch({type:"SET_SESSION",payload:JSON.parse(response.data.getSession)})
      }

