/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addReservationAsAdmin = `mutation AddReservationAsAdmin(
  $reservation: ReservationInput!
  $username: String!
) {
  addReservationAsAdmin(reservation: $reservation, username: $username) {
    reservationId
    children
    guests
    checkoutEstimated
    checkoutMade
    checkinEstimated
    checkinMade
    created
    description
    customersList {
      customerId
      fullName
      birthdate
      dni
      geo
      phone
      email
    }
    state
    way
    servicesList {
      serviceId
      cost
      quantity
      date
      name
      isPaid
      paymentsList {
        paymentId
        amount
        date
        isRefund
        method
      }
      description
    }
    roomsList
    vehiclesList {
      vehicleId
      plate
      brand
      type
    }
    otaId
    extrasList {
      extraId
      amount
      name
    }
  }
}
`;
export const addReservation = `mutation AddReservation($reservation: ReservationInput!) {
  addReservation(reservation: $reservation) {
    reservationId
    children
    guests
    checkoutEstimated
    checkoutMade
    checkinEstimated
    checkinMade
    created
    description
    customersList {
      customerId
      fullName
      birthdate
      dni
      geo
      phone
      email
    }
    state
    way
    servicesList {
      serviceId
      cost
      quantity
      date
      name
      isPaid
      paymentsList {
        paymentId
        amount
        date
        isRefund
        method
      }
      description
    }
    roomsList
    vehiclesList {
      vehicleId
      plate
      brand
      type
    }
    otaId
    extrasList {
      extraId
      amount
      name
    }
  }
}
`;
export const addService = `mutation AddService($reservationId: Int!, $service: ServiceInput) {
  addService(reservationId: $reservationId, service: $service) {
    serviceId
    cost
    quantity
    date
    name
    isPaid
    paymentsList {
      paymentId
      amount
      date
      isRefund
      method
    }
    description
  }
}
`;
export const addPayment = `mutation AddPayment($serviceId: Int!, $payment: PaymentInput) {
  addPayment(serviceId: $serviceId, payment: $payment) {
    paymentId
    amount
    date
    isRefund
    method
  }
}
`;
export const modifyRoom = `mutation ModifyRoom($room: RoomInput!) {
  modifyRoom(room: $room) {
    roomId
    state
    number
    sector
    type
  }
}
`;
export const addRoom = `mutation AddRoom($room: RoomInput!) {
  addRoom(room: $room) {
    roomId
    state
    number
    sector
    type
  }
}
`;
export const deleteRoom = `mutation DeleteRoom($roomId: Int!) {
  deleteRoom(roomId: $roomId) {
    roomId
    state
    number
    sector
    type
  }
}
`;
export const modifyReservation = `mutation ModifyReservation($reservation: ReservationInput!) {
  modifyReservation(reservation: $reservation)
}
`;
export const modifyVehicles = `mutation ModifyVehicles($reservationId: Int!, $vehiclesList: [VehicleInput!]!) {
  modifyVehicles(reservationId: $reservationId, vehiclesList: $vehiclesList) {
    vehicleId
    plate
    brand
    type
  }
}
`;
export const checkout = `mutation Checkout($bool: Boolean, $id: Int) {
  checkout(bool: $bool, id: $id)
}
`;
