/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addedReservation = `subscription AddedReservation {
  addedReservation {
    reservationId
    children
    guests
    checkoutEstimated
    checkoutMade
    checkinEstimated
    checkinMade
    created
    description
    customersList {
      customerId
      fullName
      birthdate
      dni
      geo
      phone
      email
    }
    state
    way
    servicesList {
      serviceId
      cost
      quantity
      date
      name
      isPaid
      paymentsList {
        paymentId
        amount
        date
        isRefund
        method
      }
      description
    }
    roomsList
    vehiclesList {
      vehicleId
      plate
      brand
      type
    }
    otaId
    extrasList {
      extraId
      amount
      name
    }
  }
}
`;
