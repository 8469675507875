import { Link } from "gatsby"
import {connect} from 'react-redux'
import PropTypes from "prop-types"
import React from "react"
import logo from "../images/logo.png"

const color={
  background:"#F5E0B7",
}
const Header = ({ siteTitle,isMobile }) => (
  <header
    className={"main-header"}
  >
    <div
      style={{
        margin: `0 auto`,
      }}
    >
    <Link
          className={"d-flex justify-content-center"}
          to="/"
          style={{
            textAlign:"center",
            color: `white`,
            textShadow:"-1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000",
            textDecoration: `none`,
          }}
        >
          {!isMobile&&<img  width={100}src={logo} style={{margin:"0px"}}></img>}
        </Link>

    </div>
  </header>
)
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default connect(state=>({isMobile:state.dormis.isMobile}),null)(Header)



