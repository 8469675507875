// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": `${process.env.API_URL}`, //DEV
    //"aws_appsync_graphqlEndpoint": "https://2sgsgbudifan7cmikz66tyxpzi.appsync-api.us-east-1.amazonaws.com/graphql", //PROD
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "Auth": {
        "region": "us-east-1",
        "userPoolWebClientId": "6n8a6ca2k5lu3db5f829lv5p4e",
        "userPoolId": "us-east-1_k6Vso8zqC"
    }
};


export default awsmobile;
