import React from 'react'
import moment from "moment"
import store from '../redux/store.js'
import { Label} from 'semantic-ui-react'
import {API,graphqlOperation} from 'aws-amplify';
import {actualizarInformacionHotel} from './bookingEngine'
import {buscarxdni,reservationList,roomsList} from "../graphql/queries"
import {addReservation,modifyReservation,modifyVehicles,addService,addPayment,addRoom,modifyRoom} from "../graphql/mutations"
import {addedReservation} from "../graphql/subscriptions"
import listaIngresosAction from '../redux/actions/listaIngresos'
import {ArreglarServicesList,GetPercentPayed} from '../redux/reducers/dormis'


//ACTIONS
import listaReservasAction from '../redux/actions/listaReservas'
import listaDepartamentos from '../redux/actions/setListaDepartamentos'
import addPaymentAction from '../redux/actions/addPayment'
import setReserva from '../redux/actions/setReserva'
import refrescarPrecioYDispo from '../redux/actions/refrescarPrecioYDispo'
import setDivisa from '../redux/actions/setDivisa'

export const actualizarTabla=(setLoading,dispatch)=>{
    // Actualiza el título del documento usando la API del navegador
    API.graphql({
      query: reservationList,
      variables:{tipo:"camping"},
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    }).
    then(res => {
      setLoading(false);
       const lista=res.data.reservationList;
       dispatch(listaIngresosAction(lista));
    }).catch((x)=>{console.log(x);setLoading(false)})
  }

export const actualizarListaReservas=async (setLoading,dispatch)=>{
    // Actualiza el título del documento usando la API del navegador
    const response = await API.graphql({
      query: reservationList,
      variables:{type:"departamento"},
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })

    dispatch(listaReservasAction(response.data.reservationList));
    setLoading(false)
  }

export const ActualizarListaDeptos=async (setLoading,dispatch)=>{
    // Actualiza el título del documento usando la API del navegador
    const response = await API.graphql({
      query: roomsList,
      variables:{},
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })
    dispatch(listaDepartamentos(response.data.roomsList.map(x=>({...x,room:x.number}))));
    setLoading(false)
  }

  export const MandarFormularioDepto=(ingreso,setLoading,dispatch,testFunction,onComplete=()=>{},nuevaReserva=false)=>{
    const listaReservas=store.getState().dormis.listaReservas;
    const reservation={reservation:ingreso}
      //Sirve para los test
      if  (testFunction){
        testFunction(ingreso)
      }
      else{
        API.graphql(
          graphqlOperation(addReservation,reservation)).
          then(res => {
        
            setLoading(false);
            if (res.data.addReservation)
            {
              dispatch(listaReservasAction([...listaReservas,res.data.addReservation]))
                   
              if (nuevaReserva) {
                  dispatch(setReserva(store.getState().dormis.listaReservas.find(x=>x.reservationId==res.data.addReservation.reservationId)));
                  onComplete()
              }
             
            }
            else{
              alert("Hubo un error y no se pudo agregar")
              console.log(res)
            }
          }).
          catch(error=>{
            if (error.errors[0].message.includes('phone')){
              alert("El telefono ingresado no es valido")
            }
            else{
              alert(error.errors[0].message);
            }
         
          });
      }
  }

  export const CargarDNI=(dni,setLoading,setNombre)=>{
 
    setLoading(true)
    API.graphql(
      graphqlOperation(buscarxdni,{dni})).
      then(res => {
        setLoading(false);
        setNombre(res.data.buscarxdni)
     
      }).
      catch(error=>{
        console.log(error)
        setLoading(false);
        alert(error.errors[0].message);
      });
  }

export const ActualizarVehiculos=async (reservationId,vehiclesList,dispatch)=>{
  const vehiclesListAux=vehiclesList.map(x=>{const {tableData,...y}=x;return(y)})
  const response=await API.graphql(graphqlOperation(modifyVehicles,{reservationId,vehiclesList:vehiclesListAux}))
  ActualizarListaDeptos(()=>{},dispatch)
} 

export const AgregarServicios=async (reservationId,serviceAux,dispatch,setLoading,setService=()=>{})=>{
  const response=await API.graphql(graphqlOperation(addService,{reservationId,service:serviceAux}))
  const listaReservas=store.getState().dormis.listaReservas
  const service=response.data.addService

  for(var reserva of listaReservas){
    if (reserva.reservationId==reservationId){
        reserva.servicesList=[...reserva.servicesList,service]
        reserva.servicesList=ArreglarServicesList(reserva.servicesList)
        dispatch(setReserva(reserva))
        setService(reserva.servicesList.find(x=>x.serviceId==service.serviceId))
    }
}
  dispatch(listaReservasAction(listaReservas));
  setLoading(false)
} 

export const AgregarPagos=async (serviceId,paymentAux,dispatch,setLoading,onComplete)=>{
  const response=await API.graphql(graphqlOperation(addPayment,{serviceId,payment:paymentAux}))
  const payment=response.data.addPayment;
  //dispatch(addPaymentAction({serviceId,payment:response.data.addPayment}))
  const listaReservas=store.getState().dormis.listaReservas
  var reservaAux={}
  for(var reserva of listaReservas){
      const service=reserva.servicesList.find(x=>x.serviceId==serviceId);
      if (service){
         
          service.paymentsList=[...service.paymentsList,payment]
          reserva.servicesList=ArreglarServicesList(reserva.servicesList);
          reserva.percentPayed=GetPercentPayed(reserva.servicesList);
          reservaAux=reserva;
          dispatch(setReserva(reserva))
      }
  }
  dispatch(listaReservasAction(listaReservas));
  setLoading(false)
  onComplete();
} 

export function CambiarEstadoReserva(ingreso,setLoading,onComplete,dispatch,testFunction){
  const listaReservas=store.getState().dormis.listaReservas;
  const {nights,created,servicesList,vehiclesList,percentPayed,otaId,...ingresoAux}=ingreso;
  ingresoAux.customersList=ingresoAux.customersList.map(x=>{const {age,...aux}=x;return(aux)})
  ingresoAux.roomsList=ingresoAux.roomsList.map(x=>x.roomId)
  const reservation={reservation:ingresoAux}
  setLoading(true)
  if(testFunction){
    //testFunction(graphqlOperation(cambiarEstadoReserva,{idingresos,estado,fechaIngreso,fechaSalida,departamento}))
  }
  else{
    API.graphql(
      graphqlOperation(modifyReservation,reservation)).
      then(res => {
        setLoading(false);
        if (res.data.modifyReservation){
            onComplete();
            //Sacamos el modificado de la lista
            const listaNueva=listaReservas.filter(x=>x.reservationId!=ingreso.reservationId)
            ingreso.customersList.map(x=>({...x,geo:x.geo}))
            dispatch(listaReservasAction([...listaNueva,ingreso]))
        }
        else{
          alert("Hubo un error")
        }
      }).
      catch(error=>{
        setLoading(false);
        if (error.errors[0].message.includes('phone')){
          alert("El telefono ingresado no representa un formato de telefono valido")
         
        }
        else{
          console.log(error.errors[0].message)
          alert(error.errors[0].message);
          
        }
      
      });
  }

}



export function CapitalFirst(string) 
{
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function Normalizar(string){
 return string.trim().toLocaleLowerCase().split("'").join("").split("-").join(" ").split(" ").map(x=>CapitalFirst(x)).join(" ")
}



export function ElegirColor(estado){
  switch (estado){
    case "confirmada":
      return "#2185d0"
    case "checkin":
      return "#21ba45"
    case "cancelada":
      return "black"
    case "checkout":
      return "#db2828"

  }
 
}


export function ElegirLabel(estado){
  switch (estado){
    case "confirmada":
      return   <Label  color='blue' horizontal>
      Confirmada
    </Label>
    case "checkin":
      return  <Label  color='green' horizontal>
      Checkin
    </Label>
    case "cancelada":
      return     <Label  color='black' horizontal>
      Cancelada
    </Label>
    case "checkout":
      return  <Label  color='red' horizontal>
      Checkout
    </Label>

  }
 
}

export const InformacionReservas=(listaReservas,fecha)=>{
  const fechaMoment=moment(fecha);
  var salen=[]
  var entran=[]
  var estancias=[]
  for (var item of listaReservas) {
    if (moment(item.checkoutEstimated).format("YYYY-MM-DD")==fechaMoment.format("YYYY-MM-DD")){
      salen=[...salen,item]
    }
    else if(moment(item.checkinEstimated).format("YYYY-MM-DD")==fechaMoment.format("YYYY-MM-DD")){
      entran=[...entran,item]
    }
    else if(fechaMoment.isBetween(item.checkinEstimated,item.checkoutEstimated)){
      estancias=[...estancias,item]
    }
  }
  return {salen,entran,estancias}
}

export const ActualizarTodo=async (setLoading,dispatch)=>{
   await ActualizarListaDeptos(()=>{},dispatch)
   await actualizarListaReservas(()=>{},dispatch)
   await actualizarInformacionHotel(setLoading,dispatch)
}

export const AgregarHabitacion=async (room,setLoading,dispatch)=>{
  setLoading(true)
  const response=await API.graphql(graphqlOperation(addRoom,{room}))
  ActualizarTodo(setLoading,dispatch)
}

export const ModificarHabitacion=async (room,setLoading,dispatch)=>{
  setLoading(true)
  const response=await API.graphql(graphqlOperation(modifyRoom,{room}))
  ActualizarTodo(setLoading,dispatch)
}

export const RefrescarPrecioYDispo=async (dispatch)=>{
  dispatch(refrescarPrecioYDispo(0));
}

//Actualiza la lista de precio de redux para ser usada por ejemplo cuando creamos una reserva
export const ActualizarDivisa=async (dispatch)=>{
  //Obtenemos el valor del USD
  const dolarResponse=await (await fetch(`https://www.dolarsi.com/api/api.php?type=valoresprincipales`)).json()
  var dolar=0;
  for (var item of dolarResponse) {
      if (item.casa.nombre=="Dolar Oficial") {
      dolar=parseInt((parseFloat(item.casa.compra)+parseFloat(item.casa.venta))/2)
      }
  }
  dispatch(setDivisa(dolar))
}

export const SetSubscriptions= (dispatch)=>{
  API.graphql(
    graphqlOperation(addedReservation)
    ).subscribe({
        next: (todoData) => {
           actualizarListaReservas(()=>{},dispatch)
           RefrescarPrecioYDispo(dispatch)
          // Do something with the data
        }
    });
}


export const HabitacionesDisponibles=(fechaIngreso,fechaSalida,listaReservas=null)=>{
  fechaSalida=moment(fechaSalida)
  fechaIngreso=moment(fechaIngreso)
  if(listaReservas==null){
    listaReservas=store.getState().dormis.listaReservas.filter(x=>x.state!='cancelada'&&x.state!='checkout')
  }
  
  //Los departamentos que estan tomados para las fechas
  var listaDeptos=[]
  const cantidadDias=fechaSalida.diff(fechaIngreso,"days")
  
  for (var i = 0; i < cantidadDias; i++) {
    const infoFecha=InformacionReservas(listaReservas,fechaIngreso)
    listaDeptos=[...listaDeptos,...infoFecha.entran.map(x=>x.roomsList).reduce((a,b)=>[...a,...b],[]),...infoFecha.estancias.map(x=>x.roomsList).reduce((a,b)=>[...a,...b],[])]
    fechaIngreso.add(1,"days");
  }
  var listaReturn=[]
  const departamentos=store.getState().dormis.listaDeptos;
  for (var item of departamentos){
    
    if (!listaDeptos.find(x=>x==item)){
        listaReturn=[...listaReturn,item]
    }

  }
  return listaReturn
}

export const PuedeReservar= (checkinEstimated,checkoutEstimated,reserva)=>{

//Lista de reservas sin la reserva
const listaReservasAlterada=store.getState().dormis.listaReservas.filter(x=>reserva.reservationId!=x.reservationId&&x.state!='cancelada'&&x.state!='checkout');
//Habitaciones disponibles sin tener en cuenta las habitaciones alquiladas por la reserva
const habitacionesDisponibles=HabitacionesDisponibles(checkinEstimated,checkoutEstimated,listaReservasAlterada);

for (var room of reserva.roomsList) {
  //Si no encuentra las habitaciones es porque no hay lugar
  if(habitacionesDisponibles.find(x=>x.roomId==room.roomId)==null){
      return {resultado:false,habitacion:room};
  }
  
}
return {resultado:true};
}
export const  ObtenerReservasEnCadaFecha=(fechaInicio,fechaFin)=>{
  const noches=moment(fechaFin).diff(fechaInicio,'days')
  const listaReservas=store.getState().dormis.listaReservas
  let obj=[]
  let fechaActual;
  for (let index = 0; index < noches; index++) {
    //El -1 es para arreglar algunos bugs que aparecen si chequeamos por horas exactas
    fechaActual=moment(fechaInicio).add(index,'days').add(-1,'hours')
    const listaReservasAux=listaReservas.filter(x=>fechaActual.isBetween(x.checkinEstimated,x.checkoutMade?x.checkoutMade:x.checkoutEstimated, null, '[]'));
    obj=[...obj,{fechaActual:fechaActual.format('YYYY-MM-DD'),listaReservas:listaReservasAux}]
  }

  return obj
}

export const ObtenerEstadisticas=(fechaInicio,fechaFin)=>{
 const listaReservasRepetidas=ObtenerReservasEnCadaFecha(fechaInicio,moment(fechaFin).add(1,'days').format('YYYY-MM-DD')).map(x=>x.listaReservas).flat()

 const resultado={
   mayores:listaReservasRepetidas.reduce((acom,current)=>acom+(current.customersList.filter(x=>x.age>7||x.age==0).length),0),
   menores:listaReservasRepetidas.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age<=7&&x.age>0).length,0),
   vehiculos:listaReservasRepetidas.reduce((acom,current)=>acom+current.vehiclesList.length,0),
   carpas:0,
   registros:store.getState().dormis.listaReservas.filter(x=>moment(x.created).isBetween(fechaInicio,fechaFin, null, '[]')).length
  }
 return resultado
}

export const ObtenerEstadisticasDiarias=(fecha)=>{
  const fechaInicio=moment(fecha).startOf('month').format('YYYY-MM-DD')
  const fechaFin=moment(fecha).endOf('month').add(2,'days').format('YYYY-MM-DD')
  const listaReservasRepetidas=ObtenerReservasEnCadaFecha(fechaInicio,fechaFin)
  const noches=moment(fechaFin).diff(fechaInicio,'days')
  let fechaActual;
  let resultados=[]
  for (let index = 0; index < noches; index++) {
    fechaActual=moment(fechaInicio).add(index,'days')
    let reservasActuales=listaReservasRepetidas.find(x=>x.fechaActual==fechaActual.format('YYYY-MM-DD'))
   
    if (reservasActuales) {
      reservasActuales=reservasActuales.listaReservas
    }
    else{
      continue;
    }
    resultados=[...resultados,{
      fecha:fechaActual.format('YYYY-MM-DD'),
      mayores:reservasActuales.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age>7||x.age==0).length,0),
      menores:reservasActuales.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age<=7&&x.age>0).length,0),
      vehiculos:reservasActuales.reduce((acom,current)=>acom+current.vehiclesList.length,0),
      carpas:0,
      registros:reservasActuales.filter(x=>moment(x.created).diff(fechaActual,'days')==0).length,
      ocupacion:reservasActuales.reduce((acom,current)=>acom+current.roomsList.length,0),
     }]
  }
  /*constj resultado={
    mayores:listaReservasRepetidas.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age>7||x.age==0).length,0),
    menores:listaReservasRepetidas.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age<=7&&x.age>0).length,0),
    vehiculos:listaReservasRepetidas.reduce((acom,current)=>acom+current.vehiclesList.length,0),
    carpa:0
   }*/

  return resultados
 }

 //Devuelve la estadistica de los registros que estan en el camping
 export const ObtenerEstadisticaActual=()=>{
  const listaReservas=store.getState().dormis.listaReservas.filter(x=>x.checkinMade&&!x.checkoutMade&&x.state=='checkin');
  const obj={
    mayores:listaReservas.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age>7||x.age==0).length,0),
    menores:listaReservas.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age<=7&&x.age>0).length,0),
    vehiculos:listaReservas.reduce((acom,current)=>acom+current.vehiclesList.length,0),
    registros:listaReservas.length,
    carpas:0,
    ocupacion:listaReservas.reduce((acom,current)=>acom+current.roomsList.length,0),
   }
  return obj
 }

 //Devuelve la estadistica de los registros que ingresaron hoy
 export const ObtenerEstadisticaIngresosDeHoy=()=>{
  const listaReservas=store.getState().dormis.listaReservas.filter(x=>moment(x.checkinMade).format('YYYY-MM-DD')==moment().format('YYYY-MM-DD'));
  const listaReservasSalidas=store.getState().dormis.listaReservas.filter(x=>moment(x.checkoutMade).format('YYYY-MM-DD')==moment().format('YYYY-MM-DD'));
  const obj={
    mayores:listaReservas.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age>7||x.age==0).length,0),
    menores:listaReservas.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age<=7&&x.age>0).length,0),
    vehiculos:listaReservas.reduce((acom,current)=>acom+current.vehiclesList.length,0),
    registros:listaReservas.length,
    carpas:0,
    ocupacion:listaReservas.reduce((acom,current)=>acom+current.roomsList.length,0),
    mayoresSalieron:listaReservasSalidas.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age>7||x.age==0).length,0),
    menoresSalieron:listaReservasSalidas.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age<=7&&x.age>0).length,0)
   }
  return obj
 }

 //Devuelve la estadistica de los registros que ingresaron hoy
 export const ObtenerEstadisticaOcupacion=()=>{
  const listaReservas=store.getState().dormis.listaReservas.filter(x=>moment(x.checkinMade).format('YYYY-MM-DD')==moment().format('YYYY-MM-DD'));
  const obj={
    mayores:listaReservas.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age>7||x.age==0).length,0),
    menores:listaReservas.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age<=7&&x.age>0).length,0),
    vehiculos:listaReservas.reduce((acom,current)=>acom+current.vehiclesList.length,0),
    carpas:0,
    registros:listaReservas.length
   }
  return obj
 }
//Devuelve una lista con todas las reservas que hay en cada fecha, repitiendo las reservas de quedarse mas un dia
export const  ObtenerListaPagosFecha=(fecha)=>{
  let lista=[]
  const listaReservas=store.getState().dormis.listaReservas
  for (let item of listaReservas) {
    if (item.servicesList.length>0) {
        const listaPagos=item.servicesList.map(x=>{
          if (x.paymentsList.length>0) {
              return x.paymentsList;
          }
          else{
            return [];
          }
        }).flat();
        //console.log(listaPagos)
        lista=[...lista,...listaPagos.filter(x=>moment(x.date).startOf('day').diff(moment(fecha).startOf('day'),'days')==0)]
    }
  
  }
  return lista
}
export const ListaFechas=(fechaInicio,fechaFin)=>{
  let lista=[]
  const noches=moment(fechaFin).diff(fechaInicio,'days')+1
  for (let index = 0; index < noches; index++) {
    lista=[...lista,moment(fechaInicio).add(index,'days').format('YYYY-MM-DD')]
  }
  return lista;
}